.app-footer-segment {
  padding: 0.75em 2em !important;
}

.app-footer-container {
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 1.5em;
}
