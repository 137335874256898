.app-main {
  width: 100%;
}

.year-type {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.year-round-browser {
  display: flex;
  justify-content: flex-start;
}

.year-round-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.round-button-browser {
  margin: 0em 0.5em !important;
}

.round-button-mobile {
  margin: 0.5em 1em 0em 0em !important;
  padding: 0.75em 1.75em !important;
}

.primary-filters-margin {
  margin: 0em 1em;
}

.year-type-margin {
  margin: 0.5em 0em;
}

.year-button {
  margin-right: 1em !important;
  padding: 0.75em 2.2em !important;
}

.college-margin {
  margin-top: 0.75em;
}

.state-button {
  padding: 0.7em 2em !important;
}

.form-group-margin-bottom {
  margin-bottom: 0em !important;
}

.year-note {
  margin: 1em 1em 0em 1em;
  color: gray;
}

.dropdown-filters {
  min-height: 'auto !important';
  height: 1em;
}

.secondary-filters {
  display: flex;
  justify-content: space-between;
}

.secondary-filters-col {
  display: flex;
  flex-direction: column;
}

.secondary-filters-margin {
  margin: 0.5em 1em;
}

.personlized-filter-align {
  display: flex;
  justify-content: flex-start;
  margin: 0.5em 1em;
}

.personalized-filter {
  display: flex;
  flex-direction: column;
}

.reset-margin {
  margin: 1em 0em;
}

.global-search {
  display: flex;
  justify-content: flex-end;
}

.table-margin {
  margin-top: 0.5em;
}

.github-repo {
  margin: 2em 0em 6em 0em;
}

.ui.search > .ui.input.icon {
  width: 300px;
}

.table-overflow {
  display: block;
  width: auto;
  overflow-x: scroll;
}

.scroll-to-top {
  position: fixed;
  bottom: 60px;
  right: 25px;
}

.scroll-to-top:hover {
  cursor: pointer;
}
